<!-- <div class="bg-yellow-10 my-5 p-2">
	<div class="row">
		<div class="col-sm-6">
			<p><strong>Debug Form</strong></p>
			<pre>{{ form.value | json }}</pre>

			<pre class="bg-red-5">{{ form.status }}</pre>
		</div>
		<div class="col-sm-6">
			<p><strong>Debug Filtro</strong></p>
			<pre>{{ pesquisarService.filtro| json }}</pre>
		</div>
	</div>
</div> -->

<div class="row">
	<div class="col-sm-12">
		<form [formGroup]="form">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-12 br-input" [ngClass]="{'danger': form.controls['idConcurso'].errors?.required && submetido}">
							<label for="campo-concurso">Concurso (Obrigatório):</label>
							<p-dropdown
								[options]="(pesquisarService.concursos$ | async)!"
								formControlName="idConcurso"
								(onChange)="selecionarConcurso($event)"
								placeholder="Selecione"
								[showClear]="true"
								optionLabel="nome"
								optionValue="id"
								id="campo-concurso"
								data-cy="campo-concurso"
							></p-dropdown>
							<sgp-message-feedback
								[show]="form.controls['idConcurso'].errors?.required && submetido"
								mensagem="Campo obrigatório"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-orgao">Órgão:</label>
							<div *ngIf="!somenteGestorPessoas; else somenteGPessoas">
								<p-dropdown
									[options]="(pesquisarService.orgaos$ | async)!"
									formControlName="codigoOrgao"
									(onChange)="selecionarOrgao($event.value)"
									placeholder="Selecione um concurso para habilitar o campo"
									[showClear]="true"
									optionLabel="nomeOrgao"
									optionValue="codigoOrgao"
									id="campo-orgao"
									data-cy="campo-orgao"
								></p-dropdown>
							</div>
							<ng-template #somenteGPessoas
								><div class="mt-2">{{ (this.orgaoUsuario$ | async)?.nomeReduzidoSiape }}</div></ng-template
							>
						</div>
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-cargo">Cargo:</label>
							<p-dropdown
								[options]="(pesquisarService.cargos$ | async)!"
								formControlName="codigoCargo"
								[placeholder]="placeholderCargo"
								[showClear]="true"
								optionLabel="nomeCargo"
								optionValue="codigoCargo"
								id="campo-cargo"
								data-cy="campo-cargo"
							></p-dropdown>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-cpf">CPF do candidato:</label>
							<p-inputMask
								formControlName="cpfCandidato"
								mask="999.999.999-99"
								placeholder="___.___.___-__"
								id="campo-cpf"
								data-cy="campo-cpf"
							></p-inputMask>
						</div>
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-situacao">Situação do relatório:</label>
							<p-dropdown
								[options]="(pesquisarService.situacoes$ | async)!"
								formControlName="situacao"
								placeholder="Selecione"
								[showClear]="true"
								optionLabel="descricao"
								optionValue="codigo"
								id="campo-situacao"
								data-cy="campo-situacao"
							></p-dropdown>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-envio">Período envio questionário:</label>
							<p-calendar
								[showIcon]="true"
								formControlName="periodoEnvio"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-envio"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa até dd/mm/aaaa"
								data-cy="campo-periodo-envio"
								[style]="{'minWidth':'350px'}"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="form.controls['periodoEnvio'].hasError('invalido') && submetido"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>

						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-geracao">Período geração do relatório:</label>
							<p-calendar
								[showIcon]="true"
								formControlName="periodoGeracao"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-geracao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa até dd/mm/aaaa"
								data-cy="campo-periodo-geracao"
								[style]="{'minWidth':'350px'}"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="form.controls['periodoGeracao'].hasError('invalido') && submetido"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-emissao">Período emissão do relatório:</label>
							<p-calendar
								[showIcon]="true"
								formControlName="periodoEmissao"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-emissao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa até dd/mm/aaaa"
								data-cy="campo-periodo-emissao"
								[style]="{'minWidth':'350px'}"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="form.controls['periodoEmissao'].hasError('invalido') && submetido"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-cargo">Outras opções:</label>
							<div class="mt-2">
								<div class="br-switch right">
									<input
										id="checkbox-prioridade"
										type="checkbox"
										formControlName="prioridade"
										data-cy="campo-prioridade"
									/>
									<label for="checkbox-prioridade">Prioridade solicitada</label>
								</div>
							</div>
							<div class="mt-2" *ngIf="!somenteGestorPessoas">
								<div class="br-switch right">
									<input
										id="checkbox-atribuidosMim"
										type="checkbox"
										formControlName="atribuidosMim"
										data-cy="campo-atribuidosMim"
									/>
									<label for="checkbox-atribuidosMim">Sob minha responsabilidade</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="row mt-4">
						<div class="col text-right">
							<button pButton type="reset" data-cy="btnClear" class="secondary mr-2" (click)="limpar()">Limpar</button>
							<button pButton type="submit" data-cy="btnSubmit" class="primary" (click)="pesquisar()">Pesquisar</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
